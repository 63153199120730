<template>
	<main>
		<section>
		<header>
			<h5 class="title">{{ title }}</h5>
			<div class="out" @click="$emit('close')">X</div>
		</header>
		<article>
			<div v-if="qr && qr.length" class="qr" @click="openQR">
			<img src="/assets/images/qr-code.svg" alt="" />
			</div>
			<div class="item" v-for="(item, index) in items" :key="index">
			<div v-if="item.title === 'Header'" class="header">
				<figure>
				<img v-if="item.image" :src="item.image" alt="" />
				<span v-else class="no-img">{{ item.value.charAt(0) }}</span>
				</figure>
				<span class="name"
				>{{ item.value }} <span class="id">#{{ item.id }}</span></span
				>
			</div>
			<div v-else class="text">
				<div class="title">{{ item.title }}</div>
				<template v-if="Array.isArray(item.value)">
				<div
					class="value"
					v-for="(val, arr_index) in item.value"
					:key="arr_index"
					@click="handleClick(index, arr_index)"
				>
					<img
					v-if="item.type && item.type === 'file'"
					src="/assets/images/doc-icon.svg"
					alt=""
					/>
					{{ val.name }}
				</div>
				</template>
				<template v-else>
				<div class="value">{{ item.value }}</div>
				</template>
			</div>
			</div>
		</article>
		</section>
	</main>
	<QrPdf v-if="qr.length" ref="qrPDF" :qr="qr" :title="docTitle" :serialno="SerialNo"/>
  <preview
	v-if="folder"
	:type="'folder'"
	:info="selFolder"
	@close="folder = false"
  />
</template>

<script>
import Preview from "@/components/previews/Preview.vue";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import QrPdf from '@/components/PDF/Index.vue'
export default {
  props: {
	items: {
		required: true,
		type: Array,
		default: () => [],
	},
	title: {
		required: true,
		type: String,
		default: () => "",
	},
	qr: {
		required: false,
		type: String,
		default: () => "",
	},
  },

  data() {
	return {
		folder: false,
		selFolder: {},
	};
  },

  emits: ['close'],

  components: {
	Preview,
	QrPdf
  },

  computed: {
	docTitle() {
		return this.items.filter((el) => el.title === "Header")[0].value
	},
	SerialNo() {
		return this.items.filter((el) => el.title === "Serial Nº")[0].value
	}
  },

  methods: {
	openQR() {
		html2canvas(this.$refs.qrPDF.$refs.qrPDF).then((canvas) => {
			var doc = new jsPDF("p", "px", "a4");
			const pageWidth = doc.internal.pageSize.getWidth();
			const pageHeight = doc.internal.pageSize.getHeight();

			const widthRatio = pageWidth / canvas.width;
			const heightRatio = pageHeight / canvas.height;
			const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

			const canvasWidth = canvas.width * ratio;
			const canvasHeight = canvas.height * ratio;

			const marginX = (pageWidth - canvasWidth) / 2;
			doc.addImage(canvas, "JPEG", marginX, 0.1, canvasWidth, canvasHeight);
			doc.save(
				`${this.items.filter((el) => el.title === "Header")[0].value}.pdf`
			);
		});
	},
	handleClick(index, arr_index) {
		if (this.items[index].type && this.items[index].type === "file")
		if (this.items[index].value[arr_index].files.length === 1) {
			window.open(
			process.env.VUE_APP_DO_SPACES +
				this.items[index].value[arr_index].files[0].file
			);
		} else {
			this.folder = true;
			this.selFolder = this.items[index].value[arr_index];
		}
	},
  },
};
</script>

<style lang="scss" scoped>
main {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: rgba(100,100,100,0.4);
	z-index: 5;
	display: flex;
	justify-content: center;
	align-items: center;

	section {
		background: white;
		width: 95%;
		max-width: 550px;
		height: 95%;
		max-height: 800px;
		border-radius: 2px;
		display: flex;
		flex-direction: column;
		overflow: hidden;
		position: relative;

		header, article {
			padding: 20px;
		}

		header {
			height: 10%;
			box-shadow: 0px 1px 6px #e0e0e0;
			border-bottom: 1px solid #eeeeee;
			display: flex;
			justify-content: space-between;
			align-items: center;
			position: sticky;
			top: 0;

			h5, div {
				font-weight: 600;
				font-size: 1.3rem;
			}

			.out {
				user-select: none;
				cursor: pointer;
				transition: 0.2s;

				&:hover {
					color: grey;
				}
			}
		}

		article {
			display: flex;
			flex-direction: column;
			align-items: center;
			overflow: auto;

			.qr {
				position: absolute;
				bottom: 20px;
				right: 20px;
				width: 40px;
				height: 40px;
				background: white;
				cursor: pointer;
			}

			.item {
				width: 100%;
				max-width: 400px;
				display: flex;
				justify-content: center;
				padding: 10px 0;
				border-bottom: solid 1px $grey;
				text-align: center;

				.text {
					display: flex;
					flex-direction: column;
					gap: 0.25rem;

					.title {
						font-weight: 500;
					}
				}



				.header {
					display: flex;
					flex-direction: column;
					align-items: center;
					gap: 0.5rem;

					.name {
						font-weight: 500;
						font-size: 1.3rem;
					}

					figure {
						width: 100px;
						height: 100px;
						border-radius: 50%;
						display: flex;
						justify-content: center;
						align-items: center;
						overflow: hidden;
						border: solid 2px var(--primary-color);

						img, .no-img {
							width: 100%;
							height: 100%;
						}

						img {
							object-fit: cover;
						}

						.no-img {
							background: var(--primary-color);
							color: white;
							display: flex;
							justify-content: center;
							align-items: center;
							font-weight: 500;
							font-size: 2rem;
							user-select: none;
						}
					}
				}
			}
		}
	}
}
</style>